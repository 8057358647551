import React, { useState, useContext, useEffect, useCallback } from "react"
import { Card, Button, Alert, Container, ProgressBar, Table, Image, Form, OverlayTrigger, Popover } from "react-bootstrap"
import { loggedUser,logout } from  "../components/FirebaseAuth"
import { Link, navigate } from "gatsby"

import { PageLayout } from "../components"
import { SEO } from "../utils"
import firebase from "../components/firebase"
import { UserData }  from  "../components/UserData"
import ThemeContext from "../utils/theme"

import './grid.css';

import { createAvatar } from '@dicebear/avatars';
import * as style from '@dicebear/avatars-bottts-sprites';

import aes from 'crypto-js/aes';
import utf from 'crypto-js/enc-utf8';
import ecb from 'crypto-js/mode-ecb'

import AdSense from 'react-adsense';

export default function Profile() 
{
  const [firstLoad, setFirstLoad] = useState(0)
  const [error, setError] = useState("")
  const [cats, setCats] = useState([])
  const { dark } = useContext(ThemeContext)
  const [shareID, setShareID] = useState(null)
  let currentUser

  const [userData,setUserData] = useState({})
  let previousData

  let searchParams, urlID
  if (typeof window !== 'undefined') 
  {  
    searchParams = new URLSearchParams(window.location.search);  
    urlID = searchParams.get('ID')
  }

  
  

 // console.log("Profile currentUser", currentUser )

  async function handleLogout() 
  {
    setError("")
    try 
    {
      await logout()
      navigate(`/`)
    } 
    catch 
    { 
      setError("Failed to log out")
    }
  }

  useEffect(() => 
  {
    /*firebase.firestore().collection('Categories').get()
    .then((querySnapshot) => 
    {
      let tempCats = []
      querySnapshot.docs.map((doc) => 
      {
        if (doc.exists) 
        {
          //setCats(doc.data())
          //console.log("categories",doc.data())
          let tempObj = doc.data()
          tempObj["idHash"] = doc.id
          tempCats.push(tempObj)
        } 
        else 
        {
          console.log("No categories!");
        }
      })
      setCats(tempCats)
      //console.log("categories",tempCats)

    }).catch((error) => 
    {
      console.log("Error getting categories:", error);
    });
    */
    //setCurrentUser(loggedUser())
    currentUser = JSON.parse(localStorage.getItem('profileData'))
    
    let keyphrase = 'ligma'

    
    if(currentUser)
    {
      let tempShareID = encodeURIComponent(aes.encrypt(String(currentUser.uid),keyphrase, {mode: ecb}).toString() )
      //console.log("share profile url: Profile?ID=",tempShareID)
      setShareID(tempShareID)
      //console.log('aes user ID decrypted ',aes.decrypt(shareID,keyphrase, {mode: ecb}).toString(utf))
    }

    let userStorage = JSON.parse(localStorage.getItem('userData'))

    if (urlID == null)
    {
      async function getUserInfo()
      {
        if (userStorage == null && currentUser)
        {
          //console.log("RUNNING Users FIREBASE CALL")
          //console.log("the current user is",currentUser.uid)
          await firebase.firestore().collection('Users').doc(String(currentUser.uid)).get()
          .then((doc) => {
            if (doc.exists) 
            {
                //console.log("Document data:", doc.data());
                //doc.data()
                previousData = doc.data()
                localStorage.setItem('userData', JSON.stringify(doc.data()) )
            } 
            else 
            {
                // doc.data() will be undefined in this case
                console.log("No such user!");
            }
          }).catch((error) => {
              console.log("Error getting user:", error);
          });
        }
        else 
        {
          previousData = userStorage
          //console.log("didnt run Submit Price Users firebase read because data is in local storage", userStorage)
        }
        setUserData(previousData)
      }
      getUserInfo()
    }
    else
    {      
      let IDStr = decodeURIComponent(urlID)
      //console.log("the URL ID is",IDStr)
      let decrpytedID = aes.decrypt(IDStr,keyphrase, {mode: ecb}).toString(utf)
      //console.log("the URL ID decrypted ",decrpytedID)
      //console.log("RUNNING Users FIREBASE CALL")

      async function getURLUserData()
      {
        await firebase.firestore().collection('Users').doc(String(decrpytedID)).get()
        .then((doc) => {
          if (doc.exists) 
          {
              //console.log("Document data:", doc.data());
              //doc.data()
              previousData = doc.data()
          } 
          else 
          {
              // doc.data() will be undefined in this case
              console.log("No such user!");
          }
        }).catch((error) => {
            console.log("Error getting user:", error);
        });
        
        setUserData(previousData)
      }
      getURLUserData()
    }


  }, [])

  //
  //const { userData, getUserData } = useContext(UserContext)
  //if(userData == undefined)
  //{
  //  getUserData()
  //}
  //console.log(userData)
  //

  let badgeJson = 
  {
    "Millionaire.Club":[0,"Score over 90% on houses greater than $1,000,000",0,10,"MillionairClub.svg"],
    "Mansion.Master":[0,"Score over 80% on houses larger than 3000 sqft",0,20,"Mansion.svg"],
    "Oval.Office":[0,"Complete 50 houses that have more bathrooms than bedrooms",0,50,"OvalOffice.svg"]
  }
  
  let badgeShown = 
  {
    "Millionaire.Club" : false,
    "Mansion.Master": false,
    "Oval.Office":false
  }

  let [totalScore,setScore] = useState(0)
  let userBadges = []
  useEffect(() => 
  {

    let tempScore = 0
    if(userData)
    {
      Object.entries(userData).map((t,k) =>
      {
        tempScore += t[1][0]

        let params = String(t[0])
        let divider = params.search("badge:")
        let badgeTitleDot = params.substring((divider+6))
        let badgeTitle = badgeTitleDot.replace(/\./g, ' ');

        if(divider != -1)
        {
          userBadges.push({'name':badgeTitle,'data':t[1]})
        }

        if(badgeShown[badgeTitleDot] == false)
        {
          badgeShown[badgeTitleDot] = true
        }
        
      })
      setScore(tempScore)
      userBadges.sort((a, b) => a.name.localeCompare(b.name))
      //console.log('above',badgeShown,userBadges)
    }
  },[userData])

  const EarnedBadges = useCallback(() => 
  {
    //console.log('called earned badges') 
    return(
    <>
    {
      userBadges.map((t,k) =>
      {
        let badgeTitle = String(t['name'])
        //console.log("loaded",badgeTitle)
        let des = t['data'][1]
        let progress = t['data'][2]
        let goal = t['data'][3]
        let icon = t['data'][4]
        let Badge
        let badgeWidth = 70
        let level = progress/goal * 100

        if(icon === undefined)
        {
          icon = "icon.png"
        }
        Badge = () => {
          return(
            <Image
              width={badgeWidth}
              fluid
              src={`../../icons/${icon}`}
              alt={icon}
            />
          )
        }
        return(
        <>
          <tr className="text-left" key={`badgeList-${k}`} style={{verticalAlign:'middle'}} >
            <td style={{textAlign:'center'}}>
              <Badge/><br/>
              <span style={{fontSize:'18px',fontWeight:'900'}}>{badgeTitle}</span>
            </td>
            <td style={{textAlign:'center'}}>
            <span style={{fontSize:'20px',fontWeight:'900'}}>{`${progress}/${goal}`}</span>
            </td>
            <td>
              {des}
              <ProgressBar style={{width:'95%',fontSize:'15px',height:'20px',textShadow: "#000 0px 0 5px,#000 0px 0 5px,#000 0px 0 5px"}} now={level}/>
            </td>
          </tr>
        </>
        )
      })
    }
    </>
    )
  },[userData])

  const NotEarnedBadges = useCallback(() => 
  {
    //console.log('called notEarnedBadges') 
    return(
    <>
    {
      Object.entries(badgeShown).map((t,k) =>
      {
        if(t[1] == false)
        {
          let badgeVal = badgeJson[t[0]]
          //console.log("afsafdad",badgeVal)
          let badgeTitleDot = String(t[0])
          let badgeTitle = badgeTitleDot.replace(/\./g, ' ');
          let progress = badgeVal[2]
          let icon = badgeVal[4]
          if(icon === undefined)
          {
            icon = "icon.png"
          }
          let des = badgeVal[1]
          let goal = badgeVal[3]
          let Badge
          let badgeWidth = 70
          let level = progress/goal * 100

          Badge = () => {
            return(
              <Image
                width={badgeWidth}
                fluid
                src={`../../icons/${icon}`}
                alt={`${icon}`}
              />
            )
          }
          return(
            <>
              <tr className="text-left" key={`badgeList-${k}`} style={{verticalAlign:'middle'}} >
                <td style={{textAlign:'center'}}>
                  <Badge/><br/>
                  <span style={{fontSize:'18px',fontWeight:'900'}}>{badgeTitle}</span>
                </td>
                <td style={{textAlign:'center'}}>
                <span style={{fontSize:'20px',fontWeight:'900'}}>{`${progress}/${goal}`}</span>
                </td>
                <td>
                  {des}
                  <ProgressBar style={{width:'95%',fontSize:'15px',height:'20px',textShadow: "#000 0px 0 5px,#000 0px 0 5px,#000 0px 0 5px"}} now={level}/>
                </td>
              </tr>
            </>
          )
        }
      })
    }
    </>
    )
  },[userData])

  const sharePopover = (
    
    <Popover id="popover-basic">
      <Popover.Body>
        Link Copied to Clipboard!
      </Popover.Body>
    </Popover>
  );

  const ProfileCard = useCallback(() => 
  {
    if(typeof window !== 'undefined')
    {
      if(urlID == null)
      {
        let proData = localStorage.getItem('profileData')
        if(proData == null)
        {
          let profileInfo = loggedUser()
          let neededData = {"email":profileInfo.email,"displayName":profileInfo.displayName,"uid":profileInfo.uid}
          //console.log("profile is",neededData)
          localStorage.setItem('profileData', JSON.stringify(neededData) )
        }
        proData = localStorage.getItem('profileData')
        if(proData)
        {
          let profileStorage = JSON.parse(proData)
          let profileName = profileStorage.displayName
          if (profileStorage.displayName == null)
          {
            profileName = "Name not set"
          }
          if (profileStorage.displayName == null)
          {
            profileName = "Name not set"
          }
          let seedWord = String(profileStorage.displayName).concat(String(profileStorage.email))
          return(
            <>
            <Card body style={{borderColor:'#0d0', borderWidth:'3px', maxWidth:'250px', margin:'auto'}}>
              {shareID &&
                <>
                  <OverlayTrigger trigger="click" placement="bottom" overlay={sharePopover}>
                    <Button onClick={() => {navigator.clipboard.writeText(`${window.location.hostname}/Profile?ID=${shareID}`)}} title="Copy Profile Link to Clipboard"  className="text-center">
                      Share Profile!
                    </Button>
                  </OverlayTrigger>
                </>
              }
              {profileStorage && 
              <>
                <Image
                  width={100}
                  fluid
                  src={createAvatar(style, {seed:seedWord ,dataUri:true})}
                  alt={'Profile Picture'}
                  title="Avatar is generated based on Name and Email"
                />
              </>
              }
              <h3 className="text-center">{profileStorage && profileName}</h3>
              
              <table style={{ marginLeft:"auto", marginRight: "auto"}}>
                <tbody>
                  <tr>
                    <td>
                      <Link to="/UpdateProfile" className="text-center">
                        Update&nbsp;Profile
                      </Link>
                    </td>
                    <td>
                    &nbsp;&nbsp;&#9702;
                    </td>
                    <td>
                      <div className="text-center">
                        <Button variant="link" onClick={handleLogout}>
                          Log&nbsp;Out
                        </Button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
            </>
          )
        }
      }
      else
      {
        if(userData.displayName)
        {
          //let profileStorage = JSON.parse(localStorage.getItem('profileData'))
          let seedWord = String(userData.displayName[1]).concat(String(userData.displayName[2]))
          //console.log("display name is ",userData.displayName[1])
          return(
            <>
            <Card body style={{borderColor:'#0d0', borderWidth:'3px', maxWidth:'250px', margin:'auto'}}>
              {userData && 
              <>
                <Image
                  width={100}
                  fluid
                  src={createAvatar(style, {seed: seedWord,dataUri:true})}
                  alt={'Profile Picture'}
                />
              </>
              }
              <h3 className="text-center">Profile of {userData && userData.displayName[1]}</h3>
            
            </Card>
            </>
          )
        }
        else 
        {
          return(null)
        }

      }
    }
    else 
    {
      return(null)
    }
  },[urlID,userData])


  return (
    <>
    <PageLayout>
      <SEO title="Profile" />
      <Container className="d-flex align-items-center justify-content-center" >
        <div className="w-100 text-color" style={{ maxWidth:"100%" }}>
          
          <ProfileCard/>
          <br/><br/>
          <h1>Total Score: {totalScore}</h1>
          {error && <Alert variant="danger">{error}</Alert>}

        </div>
      </Container>
      
        <div className="w-100 text-color" style={{ maxWidth: "90%", margin: "auto"}}>      
          <br/><br/>

          <h2 style={{textDecoration:'underline'}}>Badges</h2>
          <Table striped bordered hover variant={`${dark ? 'dark' : ''}`} style={{ width:'100%', margin:'auto'}}>          
            <tbody>
              <EarnedBadges/>
              <NotEarnedBadges/>
            </tbody>
          </Table>
          <br/><br/>

          <hr style={{ borderTop:`1px solid ${dark ? 'white' : 'black'}`}}/>
          
          <div className="boxes text-left features-grid thicc ">
            <u>Played Homes:</u><br/>
            <div className="thin feat-grid">
              { userData &&
                <div key={"playedHomes"} className="featBoxes">
                  <div className="played-grid">
                  {
                    Object.entries(userData).map((t,k) =>
                    {
                      let params = String(t[0])
                      let divider = params.search("_")
                      if(divider != -1)
                      {
                        let mlsParam = params.substring(0,divider)
                        let stateParam = params.substring((divider+1))
                        let address = t[1][1]
                        let imgWidth = 210 
                        let points = t[1][0]

                        return(
                          <>
                            <div className="playedBoxes" >
                              <div key={`playedList-${k}`} className="playedHouses">
                                <div className="imgContainer">
                                  <a href={`/play?state=${stateParam}&mls=${mlsParam}` } style={{color:"#00DD00"}}>
                                    <img src={address} className="imgGrayLess" alt="house" width={imgWidth} style={{border:"5px solid #00DD00",borderRadius: "10px",margin:"5px"}}></img> 
                                    <div className="imgCentered" style={{backgroundColor:"transparent", fontWeight:"900", fontSize:"1.5rem",textShadow: "#000 0px 0 5px,#000 0px 0 5px,#000 0px 0 5px"}}>
                                      Scored {points}
                                    </div>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      }
                    })
                  }
                  </div>
                </div>
              }
              { 
              /*
                cats.map((e,ind) => 
                {
                  return (
                    <>
                    <div key={`catBoxes-${ind}`} className="featBoxes" >
                      <div className="mid">{e.Name}</div>
                        { e.List &&
                          Object.entries(e.List).map((f,index) =>
                          {
                            //console.log("EEEEEE",e.idHash)
                            let played = false
                            let points = 0
                            let address = f[1]

                            let mlsParam, stateParam
                            let params = String(f[0])
                            if (params.length > 0)
                            {
                              let divider = params.search("_")
                              mlsParam = params.substring(0,divider)
                              stateParam = params.substring((divider+1))
                            }
                            userData &&
                            Object.entries(userData).map((t,k) =>
                            {
                              if(t[0] == f[0])
                              {
                                played = true
                                points = t[1][0]
                              }
                            })
                                
                            //console.log("list entry",f)
                            let imgWidth = 220 
                            if(played)
                            {
                              return(
                                <>
                                <div key={`catList-${index}`} className="playedHouses">
                                  <div className="imgContainer">
                                    <a href={`/play?state=${stateParam}&mls=${mlsParam}&listID=${e.idHash}` } style={{color:"#00DD00"}}>
                                      <img src={address} className="imgGray" alt="house" width={imgWidth} style={{border:"5px solid #00DD00",borderRadius: "10px",margin:"5px"}}></img> 
                                      <div className="imgCentered" style={{backgroundColor:"transparent", fontWeight:"900", fontSize:"1.5rem",textShadow: "#000 0px 0 5px,#000 0px 0 5px,#000 0px 0 5px"}}>
                                        Scored {points}
                                      </div>
                                    </a>
                                  </div>
                                </div>
                                </>
                              )
                            }
                            else 
                            {
                              
                              if (mlsParam!="" && stateParam!="")
                              {
                                return(
                                  <>
                                  <div key={`catsList-${index}`} style={{listStyleType:"circle"}} className="imgContainer">
                                    <a href={`/play?state=${stateParam}&mls=${mlsParam}&listID=${e.idHash}`}>
                                      <img src={address} alt="house" width={imgWidth} style={{border:"5px solid #007bff",borderRadius: "10px",margin:"5px"}} ></img> 
                                    </a>
                                  </div>
                                  </>
                                )
                              }
                            }

                          })
                        }
                    </div>
                    </>
                  )
                })
              */
              }

            </div>
          </div>
        </div>
        <AdSense.Google
          client='ca-pub-5461553569029344'
          slot='9956862050'
          style={{ display: 'block' }}
          format='auto'
          responsive='true'
          layoutKey='-gw-1+2a-9x+5c'
        />
    </PageLayout>
    </>
  )
}