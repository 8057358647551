import React, { useState, useEffect, Component } from "react"
import firebase from "../components/firebase"
import { auth } from "../components/firebase"
//import { getAuth } from "firebase/auth";
//const auth = getAuth()

export function UserData()
{ 
  const [userDataSet, setUserDataSet] = useState({})
  
  useEffect(() => 
  {
    const unsubscribe = auth.onAuthStateChanged(user => 
    {
      let userStorage = JSON.parse(localStorage.getItem('userData'))

      if (userStorage == null)
      {
        firebase.firestore().collection('Users').doc(user.uid).get()
        .then((doc) => 
        {
          if (doc.exists) 
          {
            setUserDataSet(doc.data())
            localStorage.setItem('userData', JSON.stringify(doc.data()) )
            //console.log(doc.data(),"BBBBBBBBBB")
          } 
          else 
          {
            console.log("No such user!");
          }
        }).catch((error) => 
        {
          console.log("Error getting user:", error);
        });
      }
      else 
      {
        setUserDataSet(userStorage)
        //console.log("didnt run firebase call because data is in local storage", userStorage)
      }

    })

    return unsubscribe
  }, [])

  return userDataSet

}


const defaultState = {
  userData: {},
  getUserData: () => {},
}

const UserContext = React.createContext(defaultState)

class UserProvider extends Component {
  state = {
    userData: UserData(),
  }

  getUserData = () => 
  {
    this.setState( UserData())
  }


  render() {
    const { children } = this.props
    const { userData } = this.state
    return (
      <UserContext.Provider
        value={{
          userData,
          getUserData : this.getUserData,
        }}
      >
        {children}
      </UserContext.Provider>
    )
  }
}

export default UserContext

export { UserProvider }
